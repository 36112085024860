import plugin_vue3_A0OWXRrUgq from "/home/node/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/app/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/home/node/app/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/home/node/app/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/home/node/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import localStorage_yuXkQaYqqJ from "/home/node/app/plugins/localStorage.ts";
import toast_ysMjUcU7eJ from "/home/node/app/plugins/toast.ts";
import plugin_AUP22rrBAc from "/home/node/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import auth_keycloak_9umEMarc7D from "/home/node/app/plugins/auth-keycloak.ts";
import vuetify_7h9QAQEssH from "/home/node/app/plugins/vuetify.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  localStorage_yuXkQaYqqJ,
  toast_ysMjUcU7eJ,
  plugin_AUP22rrBAc,
  auth_keycloak_9umEMarc7D,
  vuetify_7h9QAQEssH
]