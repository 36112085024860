// plugins/toast.ts
import { useToastStore } from "~/stores/toast";

export default defineNuxtPlugin((nuxtApp) => {
  const toastStore = useToastStore();

  const toast = {
    success(message: string, persist: boolean = false) {
      toastStore.addToast("success", message, persist);
    },
    error(message: string, persist: boolean = false) {
      toastStore.addToast("error", message, persist);
    },
    info(message: string, persist: boolean = false) {
      toastStore.addToast("info", message, persist);
    },
    warning(message: string, persist: boolean = false) {
      toastStore.addToast("warning", message, persist);
    },
  };

  nuxtApp.provide("stoast", toast);
});
