import en from "./en.json";
import fr from "./fr.json";
import es from "./es.json";
import it from "./it.json";

const messages = {
  en,
  fr,
  es,
  it,
};

export default messages;
