import { defineStore } from "pinia";

export const useAuthStore = defineStore({
  id: "auth",

  state: () => ({
    token: null as null | string,
    userDetails: null as any,
    groups: null as any,
    role_impersonate: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    getUserDetails: (state) => state.userDetails,
    getToken: (state) => state.token,
    getGroups: (state) => state.groups,
  },
  actions: {
    setToken(token: any) {
      this.token = token;
    },
    setUserDetails(userDetails: any) {
      this.userDetails = userDetails;
      this.groups = userDetails.groups;
      this.role_impersonate = userDetails.realm_access.roles.find((role: any) =>
        role.includes("role_impersonate")
      );
    },
  },

  persist: {
    storage: persistedState.sessionStorage,
  },
});
