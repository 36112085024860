import Keycloak, { KeycloakConfig } from "keycloak-js";
import jwtDecode from "jwt-decode";
import { useAuthStore } from "@/stores/auth";

export default defineNuxtPlugin((nuxtApp: any) => {
  const runtimeConfig = useRuntimeConfig();

  const initOptions: KeycloakConfig = {
    url: runtimeConfig.authUrl,
    realm: runtimeConfig.authRealm,
    clientId: runtimeConfig.authClientId,
  };
  const keycloak = new Keycloak(initOptions);

  const initKeycloak = async () => {
    try {
      const authStore = useAuthStore();
      authStore.setToken(null);
      const authenticated = await keycloak.init({
        onLoad: "login-required",
        // onLoad: 'check-sso',
        // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        checkLoginIframe: false,
      });

      if (authenticated) {
        localStorage.setItem("keycloak-token", `${keycloak.token}` as string);
        decodeToken(keycloak.token);
      }

      // keycloak.login();
      // onAuthenticatedCallback();
    } catch (error) {
      console.error(error);
    }
  };

  const doLogin = keycloak.login;

  const doLogout = () => {
    // localStorage.removeItem('keycloak-token');
    localStorage.clear();
    sessionStorage.clear();
    keycloak.logout();
  };
  const isValidateToken = (minValidity: any) =>
    keycloak.isTokenExpired(minValidity);

  const getToken = () => keycloak.token;

  const isLoggedIn = () => !!keycloak.token;

  const updateToken = (successCallback: any) => {
    keycloak.updateToken(5400).then(successCallback).catch(doLogin);
    localStorage.setItem("keycloak-token", `${keycloak.token}` as string);
    decodeToken(keycloak.token);
  };

  const getUsername = () => keycloak.tokenParsed?.preferred_username;

  const hasRole = (roles: any) =>
    roles.some((role: any) => keycloak.hasRealmRole(role));

  const decodeToken = (token: any) => {
    try {
      const authStore = useAuthStore();
      const decoded = jwtDecode(token);

      authStore.setToken(token);
      authStore.setUserDetails(decoded);

      return decoded;
    } catch (err) {
      console.error("Erreur lors du décodage du token:", err);
      return null;
    }
  };

  nuxtApp.provide("keycloak", {
    initKeycloak,
    doLogin,
    doLogout,
    isValidateToken,
    getToken,
    isLoggedIn,
    updateToken,
    getUsername,
    hasRole,
  });
});
