import type { ThemeTypes } from "@/types/themeTypes/ThemeType";

const DARK_BLUE_THEME: ThemeTypes = {
  name: "DARK_BLUE_THEME",
  dark: true,
  variables: {
    "border-color": "#333F55",
  },
  colors: {
    primary: "#1e88e5",
    secondary: "#0cb9c5",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FA896B",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#253662",
    lightsecondary: "#1C455D",
    lightsuccess: "#1B3C48",
    lighterror: "#4B313D",
    lightwarning: "#4D3A2A",
    textPrimary: "#EAEFF4",
    textSecondary: "#7C8FAC",
    borderColor: "#333F55",
    inputBorder: "#465670",
    containerBg: "#2a3447",
    background: "#2a3447",
    hoverColor: "#333f55",
    surface: "#2a3447",
    "on-surface-variant": "#2a3447",
    grey100: "#333F55",
    grey200: "#465670",
  },
};

const DARK_AQUA_THEME: ThemeTypes = {
  name: "DARK_AQUA_THEME",
  dark: true,
  variables: {
    "border-color": "#333F55",
  },
  colors: {
    primary: "#0074BA",
    secondary: "#47D7BC",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FA896B",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#103247",
    lightsecondary: "#0C4339",
    lightsuccess: "#1B3C48",
    lighterror: "#4B313D",
    lightwarning: "#4D3A2A",
    textPrimary: "#EAEFF4",
    textSecondary: "#7C8FAC",
    borderColor: "#333F55",
    inputBorder: "#465670",
    containerBg: "#171c23",
    background: "#171c23",
    hoverColor: "#333f55",
    surface: "#171c23",
    "on-surface-variant": "#171c23",
    grey100: "#333F55",
    grey200: "#465670",
    darkbg: "#171c23",
  },
};

const DARK_PURPLE_THEME: ThemeTypes = {
  name: "DARK_PURPLE_THEME",
  dark: true,
  variables: {
    "border-color": "#333F55",
  },
  colors: {
    primary: "#763EBD",
    secondary: "#95CFD5",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FA896B",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#26153C",
    lightsecondary: "#09454B",
    lightsuccess: "#1B3C48",
    lighterror: "#4B313D",
    lightwarning: "#4D3A2A",
    textPrimary: "#EAEFF4",
    textSecondary: "#7C8FAC",
    borderColor: "#333F55",
    inputBorder: "#465670",
    containerBg: "#171c23",
    background: "#171c23",
    hoverColor: "#333f55",
    surface: "#171c23",
    "on-surface-variant": "#171c23",
    grey100: "#333F55",
    grey200: "#465670",
    darkbg: "#171c23",
  },
};

const DARK_GREEN_THEME: ThemeTypes = {
  name: "DARK_GREEN_THEME",
  dark: true,
  variables: {
    "border-color": "#333F55",
  },
  colors: {
    primary: "#0A7EA4",
    secondary: "#CCDA4E",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FA896B",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#05313F",
    lightsecondary: "#282917",
    lightsuccess: "#1B3C48",
    lighterror: "#4B313D",
    lightwarning: "#4D3A2A",
    textPrimary: "#EAEFF4",
    textSecondary: "#7C8FAC",
    borderColor: "#333F55",
    inputBorder: "#465670",
    containerBg: "#171c23",
    background: "#171c23",
    hoverColor: "#333f55",
    surface: "#171c23",
    "on-surface-variant": "#171c23",
    grey100: "#333F55",
    grey200: "#465670",
    darkbg: "#171c23",
  },
};

const DARK_CYAN_THEME: ThemeTypes = {
  name: "DARK_CYAN_THEME",
  dark: true,
  variables: {
    "border-color": "#333F55",
  },
  colors: {
    primary: "#01C0C8",
    secondary: "#FB9678",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FA896B",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#003638",
    lightsecondary: "#40241C",
    lightsuccess: "#1B3C48",
    lighterror: "#4B313D",
    lightwarning: "#4D3A2A",
    textPrimary: "#EAEFF4",
    textSecondary: "#7C8FAC",
    borderColor: "#333F55",
    inputBorder: "#465670",
    containerBg: "#171c23",
    background: "#171c23",
    hoverColor: "#333f55",
    surface: "#171c23",
    "on-surface-variant": "#171c23",
    grey100: "#333F55",
    grey200: "#465670",
    darkbg: "#171c23",
  },
};

const DARK_ORANGE_THEME: ThemeTypes = {
  name: "DARK_ORANGE_THEME",
  dark: true,
  variables: {
    "border-color": "#333F55",
  },
  colors: {
    primary: "#FA896B",
    secondary: "#0074BA",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FA896B",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#402E32",
    lightsecondary: "#082E45",
    lightsuccess: "#1B3C48",
    lighterror: "#4B313D",
    lightwarning: "#4D3A2A",
    textPrimary: "#EAEFF4",
    textSecondary: "#7C8FAC",
    borderColor: "#333F55",
    inputBorder: "#465670",
    containerBg: "#171c23",
    background: "#171c23",
    hoverColor: "#333f55",
    surface: "#171c23",
    "on-surface-variant": "#171c23",
    grey100: "#333F55",
    grey200: "#465670",
    darkbg: "#171c23",
  },
};

export {
  DARK_BLUE_THEME,
  DARK_AQUA_THEME,
  DARK_ORANGE_THEME,
  DARK_PURPLE_THEME,
  DARK_GREEN_THEME,
  DARK_CYAN_THEME,
};
