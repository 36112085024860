// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({ app }) => {
  // Fonction pour créer l'objet local storage avec des attributs par défaut
  const createLocalStorageWithDefaults = () => {
    // Vérifier si l'objet local storage existe déjà
    if (!localStorage.getItem("tab")) {
      const tab = "roundTrip";
      localStorage.setItem("tab", tab);
    }
    if (!localStorage.getItem("option")) {
      const option = {
        directFlight: false,
        refundable: false,
        modifiable: false,
        lowest: false,
        strictSearch: false,
      };
      localStorage.setItem("option", JSON.stringify(option));
    }
    if (!localStorage.getItem("cabinType")) {
      const cabinType: string[] = [];
      localStorage.setItem("cabinType", JSON.stringify(cabinType));
    }
    if (!localStorage.getItem("fareType")) {
      const fareType: string[] = [];
      localStorage.setItem("fareType", JSON.stringify(fareType));
    }
    if (!localStorage.getItem("oras")) {
      const oras: string[] = [];
      localStorage.setItem("oras", JSON.stringify(oras));
    }
    if (!localStorage.getItem("flights")) {
      const flights: object[] = [];
      localStorage.setItem("flights", JSON.stringify(flights));
    }
    if (!localStorage.getItem("times")) {
      const times: object[] = [];
      localStorage.setItem("times", JSON.stringify(times));
    }
    if (!localStorage.getItem("paxs")) {
      const paxs: object[] = [];
      localStorage.setItem("paxs", JSON.stringify(paxs));
    }
    if (!localStorage.getItem("inputPax")) {
      const inputPax: string[] = [];
      localStorage.setItem("inputPax", JSON.stringify(inputPax));
    }
    if (!localStorage.getItem("order")) {
      const order = {};
      localStorage.setItem("order", JSON.stringify(order));
    }
    if (!localStorage.getItem("program")) {
      const program = [];
      localStorage.setItem("program", JSON.stringify(program));
    }
    if (!localStorage.getItem("editOrder")) {
      const editOrder = {};
      localStorage.setItem("editOrder", JSON.stringify(editOrder));
    }
    if (!localStorage.getItem("ads")) {
      const ads: object[] = [];
      localStorage.setItem("ads", JSON.stringify(ads));
    }
  };

  // Exécuter la fonction pour créer l'objet local storage avec des attributs par défaut
  createLocalStorageWithDefaults();
};
