<template>
  <div class="h-screen">
    <div
      v-if="loading"
      class="fixed left-0 top-0 h-0.5 w-full z-50 bg-green-500"
    ></div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup lang="ts">
const nuxtApp = useNuxtApp();
const loading = ref(false);
const interval: any = ref(null);

nuxtApp.hook("page:start", () => {
  loading.value = true;
});
nuxtApp.hook("page:finish", () => {
  loading.value = false;
});

onBeforeMount(async () => {
  await nuxtApp.$keycloak.initKeycloak();
  clearInterval(interval.value);
});

onMounted(() => {
  interval.value = setInterval(() => {
    checkToken(nuxtApp.$keycloak);
  }, 30000); // Vérifiez le token toutes les minutes
});

const checkToken = (keycloakInstance: any) => {
  const isExpired = keycloakInstance.isValidateToken(1);
  if (isExpired) {
    keycloakInstance.doLogout();
  }
};
</script>
