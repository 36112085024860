import type { ThemeTypes } from "@/types/themeTypes/ThemeType";

const BLUE_THEME: ThemeTypes = {
  name: "BLUE_THEME",
  dark: false,
  variables: {
    "border-color": "#e5eaef",
  },
  colors: {
    primary: "#1e88e5",
    secondary: "#0cb9c5",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FFAB91",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#f5fcfd",
    lightsecondary: "#E8F7FF",
    lightsuccess: "#E6FFFA",
    lighterror: "#FDEDE8",
    lightwarning: "#FEF5E5",
    textPrimary: "#2A3547",
    textSecondary: "#2A3547",
    borderColor: "#e5eaef",
    inputBorder: "#DFE5EF",
    containerBg: "#ffffff",
    background: "#eef5f9",
    hoverColor: "#f6f9fc",
    surface: "#fff",
    "on-surface-variant": "#fff",
    grey100: "#F2F6FA",
    grey200: "#EAEFF4",
    darkbg: "#2a3447",
  },
};

const AQUA_THEME: ThemeTypes = {
  name: "AQUA_THEME",
  dark: false,
  variables: {
    "border-color": "#e5eaef",
  },
  colors: {
    primary: "#0074BA",
    secondary: "#47D7BC",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FFAB91",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#EFF9FF",
    lightsecondary: "#EDFBF7",
    lightsuccess: "#E6FFFA",
    lighterror: "#FDEDE8",
    lightwarning: "#FEF5E5",
    textPrimary: "#2A3547",
    textSecondary: "#2A3547",
    borderColor: "#e5eaef",
    inputBorder: "#DFE5EF",
    containerBg: "#ffffff",
    background: "#eef5f9",
    hoverColor: "#f6f9fc",
    surface: "#fff",
    "on-surface-variant": "#fff",
    grey100: "#F2F6FA",
    grey200: "#EAEFF4",
    darkbg: "#2a3447",
  },
};

const PURPLE_THEME: ThemeTypes = {
  name: "PURPLE_THEME",
  dark: false,
  variables: {
    "border-color": "#e5eaef",
  },
  colors: {
    primary: "#763EBD",
    secondary: "#95CFD5",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FFAB91",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#F2ECF9",
    lightsecondary: "#EDF8FA",
    lightsuccess: "#E6FFFA",
    lighterror: "#FDEDE8",
    lightwarning: "#FEF5E5",
    textPrimary: "#2A3547",
    textSecondary: "#2A3547",
    borderColor: "#e5eaef",
    inputBorder: "#DFE5EF",
    containerBg: "#ffffff",
    background: "#eef5f9",
    hoverColor: "#f6f9fc",
    surface: "#fff",
    "on-surface-variant": "#fff",
    grey100: "#F2F6FA",
    grey200: "#EAEFF4",
    darkbg: "#2a3447",
  },
};

const GREEN_THEME: ThemeTypes = {
  name: "GREEN_THEME",
  dark: false,
  variables: {
    "border-color": "#e5eaef",
  },
  colors: {
    primary: "#0A7EA4",
    secondary: "#CCDA4E",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FFAB91",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#F4F9FB",
    lightsecondary: "#FAFBEF",
    lightsuccess: "#E6FFFA",
    lighterror: "#FDEDE8",
    lightwarning: "#FEF5E5",
    textPrimary: "#2A3547",
    textSecondary: "#2A3547",
    borderColor: "#e5eaef",
    inputBorder: "#DFE5EF",
    containerBg: "#ffffff",
    background: "#eef5f9",
    hoverColor: "#f6f9fc",
    surface: "#fff",
    "on-surface-variant": "#fff",
    grey100: "#F2F6FA",
    grey200: "#EAEFF4",
    darkbg: "#2a3447",
  },
};

const CYAN_THEME: ThemeTypes = {
  name: "CYAN_THEME",
  dark: false,
  variables: {
    "border-color": "#e5eaef",
  },
  colors: {
    primary: "#01C0C8",
    secondary: "#FB9678",
    info: "#539BFF",
    success: "#00e676",
    accent: "#FFAB91",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#EBF9FA",
    lightsecondary: "#FFF5F2",
    lightsuccess: "#E6FFFA",
    lighterror: "#FDEDE8",
    lightwarning: "#FEF5E5",
    textPrimary: "#2A3547",
    textSecondary: "#2A3547",
    borderColor: "#e5eaef",
    inputBorder: "#DFE5EF",
    containerBg: "#ffffff",
    background: "#eef5f9",
    hoverColor: "#f6f9fc",
    surface: "#fff",
    "on-surface-variant": "#fff",
    grey100: "#F2F6FA",
    grey200: "#EAEFF4",
    darkbg: "#2a3447",
  },
};

const ORANGE_THEME: ThemeTypes = {
  name: "ORANGE_THEME",
  dark: false,
  variables: {
    "border-color": "#e5eaef",
  },
  colors: {
    primary: "#FA896B",
    secondary: "#0074BA",
    info: "#539BFF",
    success: "#00e676",
    accent: "#FFAB91",
    warning: "#ffe57f",
    error: "#FA896B",
    lightprimary: "#FBF2EF",
    lightsecondary: "#EFF9FF",
    lightsuccess: "#E6FFFA",
    lighterror: "#FDEDE8",
    lightwarning: "#FEF5E5",
    textPrimary: "#2A3547",
    textSecondary: "#2A3547",
    borderColor: "#e5eaef",
    inputBorder: "#DFE5EF",
    containerBg: "#ffffff",
    background: "#eef5f9",
    hoverColor: "#f6f9fc",
    surface: "#fff",
    "on-surface-variant": "#fff",
    grey100: "#F2F6FA",
    grey200: "#EAEFF4",
    darkbg: "#2a3447",
  },
};

export {
  BLUE_THEME,
  AQUA_THEME,
  ORANGE_THEME,
  PURPLE_THEME,
  GREEN_THEME,
  CYAN_THEME,
};
