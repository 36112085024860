import { defineStore } from "pinia";

export const useToastStore = defineStore("toast", {
  state: () => ({
    messages: [] as { type: string; text: string }[],
  }),
  actions: {
    addToast(type: string, text: string, persist: boolean = false) {
      const index = this.messages.push({ type, text }) - 1;
      if (!persist) setTimeout(() => this.removeToast(index), 5000);
    },
    removeToast(index: number) {
      this.messages.splice(index, 1);
    },
  },
});
